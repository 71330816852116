import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import moment from "moment";
import { SnackbarProvider, useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from 'react-dropzone';
import { useNavigate, useParams } from 'react-router-dom';
import { DotLoaderOverlay } from 'react-spinner-overlay';
import { saveAs } from 'save-as';
import { v4 as uuidv4 } from 'uuid';

/** MUI */
import {
    AppBar,
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    CircularProgress,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography
} from "@mui/material";

/** Iconos */
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FolderIcon from '@mui/icons-material/Folder';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';

//import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//import esLocale from 'date-fns/locale/es';
import 'dayjs/locale/es';

import dayjs from 'dayjs';


/** FireBase */
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";

import {
    MaterialReactTable
} from "material-react-table";
import Modal from "react-responsive-modal";
import ResponsiveAppBar from "../../components/navbar.component";
import FireBaseApp from "../../firebase/firebase";

/**Servicios */
import emailService from "../../services/email.service";

function nombreArchivo(arhivo) {
    var nom = arhivo;
    var extension = /[^.]+$/.exec(nom);
    var sp = "." + extension;
    var nom_sin_extension = nom.split(sp);
    return nom_sin_extension;
};

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {`${props.value}%`}
                </Typography>
            </Box>
        </Box>
    );
};

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const DocsResso = () => {
    const { id_contrato, id_item, id_subcategoria } = useParams();
    const [archivo, setArchivo] = useState('');
    const [nombre, setNombre] = useState('');
    const [percent, setPercent] = useState(0);
    const storage = getStorage(FireBaseApp.app);
    const [politicas, setPoliticas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [checkFechav, setCheckFechav] = useState(true);
    const [checkNC, setCheckNC] = useState(true);
    const navitate = useNavigate();
    const [item, setItem] = useState('');
    const [fechaVencimiento, setFechaVencimiento] = useState('');
    const [fechaCreacion, setFechaCreacion] = useState('');
    const [nc, setNc] = useState('');
    const [observacion, setObservacion] = useState('');
    const [edit, setEdit] = useState(false);
    const [idDoc, setIdDoc] = useState('');
    const [ponderado, setPonderado] = useState('');
    const [valorPonderado, setValorPonderado] = useState(0);
    const { enqueueSnackbar } = useSnackbar();
    const [observacionPunto, setObservacionPunto] = useState('');
    const [modalBrecha, setModalBrecha] = useState(false);
    const [ingresoBrecha, setIngresoBrecha] = useState(false);
    const [textBrecha, setTextBrecha] = useState('');
    const [fechaLimite, setFechaLimite] = useState('');
    const [responsable, setResponsable] = useState('');
    const [status, setStatus] = useState(false);
    const [estado, setEstado] = useState('');
    const [correoResponsable, setCorreoResponsable] = useState('');
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    const [percentage, setPercentage] = useState(0);
    const [contrato, setContrato] = useState('');
    const [modalAuditoria, setModalAuditoria] = useState(false);
    const [auditoria, setAuditoria] = useState('');
    const [auditoriaIngresada, setauditoriaIngresada] = useState(false);
    const [crearCarpeta, setCrearCarpeta] = useState(false);
    const [nombreCarpeta, setNombreCarpeta] = useState('');
    const [carpetas, setCarpetas] = useState([]);
    const inputRef = useRef(null);
    const [nombreDocCarpeta, setNombreDocCarpeta] = useState('');


    useEffect(() => {
        retreivePoliticas(id_item);
        retreiveItem(id_item);
        retreiveContrato(id_contrato);
        retreiveCarpetas(id_contrato, id_item);
        //retreiveAuditoria(id_contrato);
    }, []);

    const closeModalBrecha = () => setModalBrecha(false);
    const closeModalAuditoria = () => setModalAuditoria(false);

    async function retreiveCarpetas(id_contrato, id_item) {
        setLoading(true);
        var temp_arr = [];
        const refCarpeta = await query(collection(FireBaseApp.db, "carpetas"), where("id_contrato", "==", id_contrato), where("id_item","==",id_item));
        const dataCarpeta = await getDocs(refCarpeta);
        if (!dataCarpeta.empty) {
            dataCarpeta.forEach((docC) => {
                const dataC = { id: docC.id, ...docC.data() };
                temp_arr.push(dataC);
                setCarpetas([...temp_arr]);
            });
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    async function retreivePoliticas(id_item) {
        setLoading(true);
        var arr_temp = [];
        const queryPol = await query(collection(FireBaseApp.db, "documentos_resso"), where("item", "==", id_item));
        const snapPol = await getDocs(queryPol);
        if (!snapPol.empty) {
            snapPol.forEach((document) => {
                var data = { id: document.id, ...document.data() };
                arr_temp.push(data);
            });
            setPoliticas([...arr_temp]);
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    async function retreivePoliticasCarpeta(id_item, carpeta) {
        setLoading(true);
        var arr_temp = [];
        const queryPol = await query(collection(FireBaseApp.db, "documentos_resso"), where("item", "==", id_item), where("carpeta","==",carpeta));
        const snapPol = await getDocs(queryPol);
        if (!snapPol.empty) {
            snapPol.forEach((document) => {
                var data = { id: document.id, ...document.data() };
                arr_temp.push(data);
            });
            setPoliticas([...arr_temp]);
            setLoading(false);
        } else {
            setPoliticas([...arr_temp]);
            setLoading(false);
        }
    };

    async function retreiveContrato(id_cont) {
        const contData = await getDoc(doc(FireBaseApp.db, "contrato", id_cont));
        setContrato(contData.data());
    };

    async function retreiveItem(id_item) {
        setLoading(true);
        const refItem = await doc(FireBaseApp.db, "items", id_item);
        const docItem = await getDoc(refItem);
        setItem({ id: docItem.id, ...docItem.data() });
        retreiveAuditoria(id_contrato, docItem.data().numero);
        //Get Ponderado
        const queryPond = query(collection(FireBaseApp.db, "ponderado"), where("id_item", "==", docItem.id));
        const snapPond = await getDocs(queryPond);
        if (!snapPond.empty) {
            setPonderado({ id: snapPond.docs[0].id, ...snapPond.docs[0].data() });
        }
        setLoading(false);
    };

    const columns = [
        {
            accessorKey: 'nombre',
            header: 'Nombre',
        },
        {
            accessorKey: 'url',
            header: 'Descarga',
            Cell: ({ cell }) => {
                return (
                    <IconButton onClick={() => window.open(cell.getValue(), "_blank")}>
                        <PictureAsPdfIcon color="success" />
                    </IconButton>
                )
            }
        },
        {
            accessorKey: 'fecha_creacion',
            header: 'Fecha Creación',
            Cell: ({ cell }) => {
                if (cell.getValue()) {
                    return (
                        <p>{moment(cell.getValue().toDate()).format("DD-MM-YYYY")}</p>
                    )
                } else {
                    return (
                        <p>Sin Fecha</p>
                    )
                }
            }
        },
        {
            accessorKey: 'fecha_vencimiento',
            header: 'Fecha Vencimiento',
            Cell: ({ cell }) => {
                if (cell.getValue()) {
                    return (
                        <p>{moment(cell.getValue().toDate()).format("DD-MM-YYYY")}</p>
                    )
                } else {
                    return (
                        <p>Sin Fecha</p>
                    )
                }

            }
        },
        {
            accessorKey: 'nivel_cumplimiento',
            header: 'Nivel de Cumplimiento',
        },
        {
            accessorKey: 'observacion',
            header: 'Observación',
        },
        {
            accessorKey: 'carpeta',
            header: 'Carpeta',
        },
        {
            accessorKey: 'id',
            header: 'Editar',
            Cell: ({ cell, row }) => {
                return (
                    <IconButton onClick={() => editarDocumentoTable(row.original.id)}>
                        <EditIcon sx={{ color: "grey" }} />
                    </IconButton>
                )
            }
        },
        {
            accessorKey: 'id_',
            header: 'Eliminar',
            Cell: ({ cell, row }) => {
                return (
                    <IconButton onClick={() => eliminarDocumento(row.original.id)}>
                        <DeleteIcon color="error" />
                    </IconButton>
                )
            }
        },
    ];

    const guardarPolitica = () => {
        if (!archivo) {
            alert("Debe agregar un archivo");
            return;
        }
        if (!nombre) {
            alert("Debe ingresar un nombre al documento");
            return
        }

        //Subimos el Archivo
        const storageRef = ref(storage, `/docs_resso/${archivo.name}`);
        const uploadTask = uploadBytesResumable(storageRef, archivo);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );

                // update progress
                setPercent(percent);
            },
            (err) => console.error(err),
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                    //Actualizamos el derivado
                    const docRef = await addDoc(collection(FireBaseApp.db, "documentos_resso"), {
                        nombre: nombre,
                        url: url,
                        item: id_item,
                        id_contrato: id_contrato,
                        nivel_cumplimiento: nc,
                        observacion: observacion,
                        fecha_creacion: fechaCreacion,
                        fecha_vencimiento: fechaVencimiento,
                        have_nc: checkNC,
                        have_fv: checkFechav,
                        carpeta: nombreDocCarpeta,
                    });
                    setPercent(0);
                    retreivePoliticas(id_item);
                });
            }
        );
    };

    const handleArchivo = (valor) => {
        setLoading(true);
        setArchivo(valor.target.files[0]);
        setLoading(false);
    };

    const eliminarDocumento = async (id_documento) => {
        const docRef = await doc(FireBaseApp.db, "documentos_resso", id_documento);
        deleteDoc(docRef);
        retreivePoliticas(id_item);
        retreiveItem(id_item);
    };

    const editarDocumentoTable = async (id_documento) => {
        const docRef = await doc(FireBaseApp.db, "documentos_resso", id_documento);
        const docSnap = await getDoc(docRef);
        setEdit(true);
        console.log(docSnap.data());
        setNombre(docSnap.data().nombre);
        if(docSnap.data().fecha_creacion !== "") {
            setFechaCreacion(dayjs(docSnap.data().fecha_creacion.toDate()));
        }
        if(docSnap.data().fecha_vencimiento !== "") {
            setFechaVencimiento(dayjs(docSnap.data().fecha_vencimiento.toDate()));
        }
        //setFechaCreacion((docSnap.data().fecha_creacion !== undefined || docSnap.data().fecha_creacion !== "") ? dayjs(docSnap.data().fecha_creacion.toDate()) : "");
        //setFechaVencimiento(docSnap.data().fecha_vencimiento !== undefined || docSnap.data().fecha_vencimiento !== "" ? dayjs(docSnap.data().fecha_vencimiento.toDate()) : "");
        setNc(docSnap.data().nivel_cumplimiento !== undefined ? docSnap.data().nivel_cumplimiento : "");
        setObservacion(docSnap.data().observacion !== undefined ? docSnap.data().observacion : "");
        setCheckFechav(docSnap.data().have_fv == undefined ? true : docSnap.data().have_fv);
        setCheckNC(docSnap.data().have_nc == undefined ? true : docSnap.data().have_nc);
        setIdDoc(docSnap.id);
        inputRef.current.focus();
    };

    const editarDocumento = async () => {
        const docRef = await doc(FireBaseApp.db, "documentos_resso", idDoc);
        updateDoc(docRef, {
            nombre: nombre,
            item: id_item,
            id_contrato: id_contrato,
            nivel_cumplimiento: nc,
            observacion: observacion,
            fecha_creacion: fechaCreacion,
            fecha_vencimiento: fechaVencimiento,
            have_nc: checkNC,
            have_fv: checkFechav,
            carpeta: nombreDocCarpeta,
        });
        if (archivo) {
            //Subimos el Archivo
            const storageRef = ref(storage, `/docs_resso/${archivo.name}`);
            const uploadTask = uploadBytesResumable(storageRef, archivo);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    setPercent(percent);
                },
                (err) => console.error(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                        //Actualizamos el derivado
                        updateDoc(docRef, {
                            url: url,
                        });
                        setPercent(0);
                    });
                }
            );
        }
        retreivePoliticas(id_item);
        retreiveItem(id_item);
    };

    const handlePonderador = (event) => {
        setValorPonderado(event.target.value);
        event.target.checked = true;
    };

    const actualizarPonderado = async (estado) => {
        if (valorPonderado === 100 || ponderado.indicadores.filter(v => v.estado === true)[0].valor === 100) {
            setLoading(true);
            //Obtenemos el item para calcular la ponderación
            const itemRef = await doc(FireBaseApp.db, "items", item.id);
            const itemData = await getDoc(itemRef);
            var resultado = (valorPonderado / 100) * itemData.data().ponderacion;
            updateDoc(itemRef, {
                resultado: resultado,
                porcentaje: valorPonderado
            });
            setLoading(false);
            enqueueSnackbar("Ponderación actualizada correctamente !!", { variant: 'success' });
        } else {
            var valorPond = ponderado.indicadores.filter(v => v.estado === true)[0].valor;
            setLoading(true);
            //Obtenemos el item para calcular la ponderación
            const itemRef = await doc(FireBaseApp.db, "items", item.id);
            const itemData = await getDoc(itemRef);
            var resultado = (valorPond / 100) * itemData.data().ponderacion;
            updateDoc(itemRef, {
                resultado: resultado,
                porcentaje: valorPond
            });
            setLoading(false);
            //enqueueSnackbar("No ha ingresado ninguna Brecha !!", { variant: 'error' });
            //Ingresamos Brecha
            if (valorPond < 100 && estado) {
                setModalBrecha(true);
                setStatus(false);
            }
        }
    };

    const handleEstadoPonderado = async (index, valor) => {
        //Setamos los indicadores a false
        ponderado.indicadores.map(ind => {
            ind.estado = false;
        });
        ponderado.indicadores[index].estado = valor;
        setPonderado({ ...ponderado });
        //Actualizamos el ponderado en la BD
        const pondRef = await doc(FireBaseApp.db, "ponderado", ponderado.id);
        const pondData = await getDoc(pondRef);
        var indi = pondData.data().indicadores;
        indi[index].estado = valor;
        updateDoc(pondRef, {
            indicadores: ponderado.indicadores,
        });
    };

    const enviarObservacionPunto = async () => {
        const refItem = await doc(FireBaseApp.db, "items", id_item);
        updateDoc(refItem, {
            observacion: observacionPunto,
        });
        enqueueSnackbar("Observaciones enviadas correctamente !!", { variant: 'success' });
    };

    function creatHtml(brecha, fecha_limite, n_item, nombre_contrato) {
        var html = `
        <p>Estimado: </p><br />
        <p>El presente correo es para informar que usted tiene una brecha la cual debe poner atención y acción para el cumplimiento del programa RESSO:</p><br />
        <p>Brecha: <strong>${brecha}<strong></p><br />
        <p>Número ítem: <strong>${n_item}<strong></p><br />
        <p>Contrato: <strong>${nombre_contrato}<strong></p><br />
        <p>Plazo máximo para resolver: ${fecha_limite}</p><br />
        `
        return html;
    }

    const agregarBrecha = async () => {
        if (!textBrecha || !fechaLimite || !responsable) {
            alert("Faltan completar Datos ...!!");
            return;
        } else {
            //Agregamos Brecha
            setLoading(true);
            const brechRef = addDoc(collection(FireBaseApp.db, "brechas"), {
                fecha_limite: fechaLimite,
                brecha: textBrecha,
                responsable: responsable,
                numero_item: item.numero,
                id_contrato: id_contrato,
                fecha_ingreso: new Date(),
                id_subcategoria: id_subcategoria,
                correo_responsable: correoResponsable,
                estado: estado,
                id_item: id_item
            });
            //Obtenemos el contrato
            const contData = await getDoc(doc(FireBaseApp.db, "contrato", id_contrato));
            //setLoading(false);
            //Enviamos el mail
            var mensaje = creatHtml(textBrecha, fechaLimite, item.numero, contData.data().abreviado);
            const data_mail = {
                email: correoResponsable,
                mensaje: mensaje,
            }
            emailService.sendEmail(data_mail)
                .then(resp => {
                    console.log(resp);
                    enqueueSnackbar("Correo enviado con exito!!", { variant: 'success' });
                })
                .catch(err => {
                    console.error(err);
                    enqueueSnackbar("Ha ocurrido un error al enviar el correo", { variant: 'error' });
                })
            //Actualizamos Pnderado
            setStatus(true);
            actualizarPonderado(status);
            closeModalBrecha();
            setLoading(false);
            enqueueSnackbar("Brecha ingresada correctamente !!", { variant: 'success' });
        };
    };

    function enviarCorreo(correo_responsable) {

    }

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const guardarMasivo = () => {

        if (!files) { //!archivo || 
            alert("Debe agregar un archivo");
            return;
        }
        for (var f in acceptedFiles) {
            //Subimos el Archivo
            const nombre_file = nombreArchivo(acceptedFiles[f].path);
            const storageRef = ref(storage, `/docs_resso/${acceptedFiles[f].path}`); //acceptedFiles[f].path
            const uploadTask = uploadBytesResumable(storageRef, acceptedFiles[f]);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    setPercent(percent);
                },
                (err) => console.error(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                        console.log(url);
                        //Actualizamos el derivado
                        const docRef = await addDoc(collection(FireBaseApp.db, "documentos_resso"), {
                            nombre: nombre_file,
                            url: url,
                            item: id_item,
                            id_contrato: id_contrato,
                            nivel_cumplimiento: nc,
                            observacion: observacion,
                            fecha_creacion: fechaCreacion,
                            fecha_vencimiento: fechaVencimiento,
                            have_nc: checkNC,
                            have_fv: checkFechav,
                            carpeta: nombreDocCarpeta,
                        });
                        setPercent(0);
                        retreivePoliticas(id_item);
                        retreiveItem(id_item);
                    });
                }
            );
        }
    };

    function urlToPromise(url) {
        setLoading(true);
        console.log("URL", url);
        return new Promise(function (resolve, reject) {
            JSZipUtils.getBinaryContent(url, function (err, data) {
                if (err) {
                    reject(err);
                    setLoading(false);
                } else {
                    resolve(data);
                    setLoading(false);
                }
            });
        });
    }

    const descargarArchivos = () => {
        for (var i in politicas) {
            var reference = ref(storage, politicas[i].url);
            console.log("Ref", reference);
        }
        setLoading(true);
        var rut_ = "Punto " + item.numero.toString();
        console.log("Carpeta", rut_);
        var zip = new JSZip();
        const fold = zip.folder(rut_);
        politicas.map(d => {
            if (d.url !== null) {
                var reference = ref(storage, d.url);
                var filename = reference.name;
                console.log("Nombre Archivo", filename);
                fold.file(filename, urlToPromise(d.url), { binary: true });
            }
        });
        zip.generateAsync({ type: 'blob' }, function updateCallback(metadata) {
            setPercentage(metadata.percent.toFixed(2));
            /* if (metadata.currentFile) {
                console.log("current file = " + metadata.currentFile);
            } */
        })
            .then(function (content) {
                saveAs(content, rut_ + ".zip");
                setPercentage(0);
            });
        setLoading(false);
    };

    const handleAuditoria = (valor, nombre) => {
        setAuditoria({ ...auditoria, [nombre]: valor });
    };

    const ingresarAuditoria = async () => {
        console.log("Auditoria", auditoria);
        if (!auditoria.calificacion_halazgo) {
            enqueueSnackbar("Falta agregar la Calificación de Hallazgo", { variant: 'info' });
            return;
        }
        if (!auditoria.descripcion_hallazgo) {
            enqueueSnackbar("Falta agregar la Descripción de Hallazgo", { variant: 'info' });
            return;
        }
        if (!auditoria.accion_correctiva) {
            enqueueSnackbar("Falta agregar la Acción Correctiva", { variant: 'info' });
            return;
        }
        if (!auditoria.responsable) {
            enqueueSnackbar("Falta agregar el Responsable", { variant: 'info' });
            return;
        }
        if (!auditoria.fecha_implementacion) {
            enqueueSnackbar("Falta agregar la Fecha de Implemantación", { variant: 'info' });
            return;
        }
        //Buscamos si existe auditoria para el contrato y si esta abierta
        setLoading(true);
        const queryAuditDoc = await query(collection(FireBaseApp.db, "auditoria_documental"), where("id_contrato", "==", id_contrato), where("estado", "==", true));
        const dataAuditDoc = await getDocs(queryAuditDoc);
        const data_a = {
            id: uuidv4(),
            item: item.numero,
            descripcion_item: item.nombre,
            ...auditoria
        };
        if (dataAuditDoc.empty) {
            //TODO Ingresamos una nueva auditoria
            console.log("Ingresamos auditoria");
            const arr_a = [];
            arr_a.push(data_a);
            const data_auditoria = {
                id_contrato: id_contrato,
                fecha: new Date(),
                estado: true,
                arr_auditorias: [data_a],
            }
            const dataAudit = await addDoc(collection(FireBaseApp.db, "auditoria_documental"), data_auditoria);
            setLoading(false);
        } else {
            //TODO agregamos a arreglo de auditoria
            //Preguntamos si ya existe el punto en la auditoria
            const dAudit = { id: dataAuditDoc.docs[0].id, ...dataAuditDoc.docs[0].data() };
            dAudit.arr_auditorias.push(data_a);
            //Actualizamos la lista de la Auditoria
            const dataAupdate = await doc(FireBaseApp.db, "auditoria_documental", dAudit.id);
            updateDoc(dataAupdate, {
                arr_auditorias: dAudit.arr_auditorias,
            });
            setLoading(false);
        }
        //Limpiamos auditoria
        setAuditoria('');
    };

    async function retreiveAuditoria(id_contrato, numero_item) {
        const queryAuditDoc = await query(collection(FireBaseApp.db, "auditoria_documental"), where("id_contrato", "==", id_contrato), where("estado", "==", true));
        const dataAuditDoc = await getDocs(queryAuditDoc);
        if (!dataAuditDoc.empty) {
            const dAudit = { id: dataAuditDoc.docs[0].id, ...dataAuditDoc.docs[0].data() };
            console.log("DAudit", dAudit);
            const findA = dAudit.arr_auditorias.find(v => v.item == numero_item);
            console.log("Find A", findA);
            if (findA) {
                setauditoriaIngresada(true);
            } else {
                setauditoriaIngresada(false);
            }
        }

    };

    const crearC = async () => {
        if (!nombreCarpeta) {
            alert("Debe ingresar el nombre de la carpeta");
            return;
        }
        setLoading(true);
        var ncarpeta = nombreCarpeta.toUpperCase();
        const refCarpeta = await addDoc(collection(FireBaseApp.db, "carpetas"), {
            nombre_carpeta: ncarpeta,
            id_contrato: id_contrato,
            id_item: id_item,
        });
        console.log(refCarpeta.id);
        setLoading(false);
    };

    const selCarpeta = (nombre_carpeta) => {
        console.log("Nombre Car", nombre_carpeta);
        //Filtramos por nombre de carpeta
        if (nombre_carpeta !== "TODAS") {
            /* var arr_pol = politicas.filter(v => v.carpeta === nombre_carpeta);
            setPoliticas(arr_pol); */
            retreivePoliticasCarpeta(id_item, nombre_carpeta);
        } else {
            retreivePoliticas(id_item);
        }

    }

    return (
        <div>
            {loading ? (
                <DotLoaderOverlay size={28} message="Cargando..." color="green" />
            ) : ""}
            <ResponsiveAppBar id_contrato={id_contrato} id_empresa={contrato.id_empresa} />
            <Container>
                <Box>
                    <Box sx={{
                        margin: 3,
                        justifyContent: "start",
                        alignItems: "start",
                        display: "flex"
                    }}>
                        <Button variant="contained" color="secondary" onClick={() => navitate("/inicio/" + id_contrato + "/" + id_subcategoria)}>Volver</Button>
                    </Box>
                    <Box sx={{
                        margin: 3,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex"
                    }}>
                        <Typography variant="h6">
                            {item.numero + " - " + item.nombre}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{
                    margin: 3,
                    justifyContent: "end",
                    alignContent: "end",
                    alignItems: "end",
                    display: "flex"
                }}>
                    <Card sx={{
                        width: "100%",
                        boxShadow: 3
                    }}>
                        <CardContent>
                            {ponderado !== "" ? (
                                <>
                                    <FormControl>
                                        <FormLabel id="demo-radio-buttons-group-label">Ponderado</FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                            value={valorPonderado}
                                            onChange={handlePonderador}
                                        >
                                            {ponderado.indicadores.map((ind, index) => {
                                                return (
                                                    <>
                                                        <FormControlLabel key={index} onChange={() => handleEstadoPonderado(index, !ind.estado)} value={ind.valor} control={<Radio />} label={ind.nombre} checked={ind.estado} />
                                                    </>
                                                )

                                            })}
                                        </RadioGroup>
                                    </FormControl>
                                    <Box sx={{
                                        margin: 3,
                                        justifyContent: "end",
                                        alignContent: "end",
                                        alignItems: "end",
                                        display: "flex"
                                    }}>
                                        <Button variant="contained" onClick={actualizarPonderado}>Actualizar</Button>
                                    </Box>
                                </>
                            ) : ""}
                        </CardContent>
                    </Card>
                </Box>
                {/** INGRESO DE AUDITORIA JEFE HSE */}
                <Box sx={{
                    margin: 3,
                    justifyContent: "end",
                    alignContent: "end",
                    alignItems: "end",
                    display: "flex"
                }}>
                    <Card sx={{
                        width: "100%",
                        boxShadow: 3
                    }}>
                        <CardContent>
                            <Stack direction="row" spacing={3}>
                                <Button variant='contained' sx={{ backgroundColor: 'black' }} onClick={() => setModalAuditoria(true)} disabled={auditoriaIngresada}>INGESAR AUDITORIA</Button>
                                {auditoriaIngresada ? (<p>Auditoria Ingresada!</p>) : ""}
                            </Stack>
                        </CardContent>
                    </Card>
                </Box>
                <Box sx={{
                    margin: 3,
                    justifyContent: "end",
                    alignContent: "end",
                    alignItems: "end",
                    display: "flex"
                }}>
                    <Card sx={{
                        width: "100%",
                        boxShadow: 3
                    }}>
                        <CardContent>
                            <TextField
                                rows={5}
                                multiline
                                fullWidth
                                label="Observaciones del Punto"
                                onChange={(e) => setObservacionPunto(e.target.value)}
                            />
                            <Box sx={{
                                margin: 3,
                                justifyContent: "end",
                                alignContent: "end",
                                alignItems: "end",
                                display: "flex"
                            }}>
                                <Button variant="contained" onClick={enviarObservacionPunto}>Enviar</Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                <Box sx={{
                    margin: 3,
                    alignContent: "center",
                    justifyContent: "center",
                    display: "flex"
                }}>
                    <Grid container spacing={3}>
                        <Grid item sm={4}>
                            <TextField
                                label='Nombre Documento'
                                fullWidth
                                onChange={(e) => setNombre(e.target.value)}
                                value={nombre}
                                inputRef={inputRef}
                            />
                        </Grid>
                        <Grid item sm={4}>
                            {/* <TextField
                                label='Fecha Creación'
                                fullWidth
                            /> */}
                            {console.log(fechaCreacion)}
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                <DatePicker
                                    label="Fecha Cración"
                                    format='DD-MM-YYYY'
                                    value={fechaCreacion}
                                    onChange={(newValue) => setFechaCreacion(newValue.toDate())}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item sm={4}>
                            {/* <TextField
                                label='Fecha Vencimiento'
                                fullWidth
                                disabled={checkFechav}
                            /> */}
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                <DatePicker
                                    label="Fecha Vencimiento"
                                    format='DD-MM-YYYY'
                                    value={fechaVencimiento}
                                    onChange={(newValue) => setFechaVencimiento(newValue.toDate())}
                                    disabled={checkFechav}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item sm={4}>
                            <TextField
                                label='Nivel Cumplimiento'
                                disabled={checkNC}
                                fullWidth
                                name="nc"
                                value={nc}
                                onChange={(e) => setNc(e.target.value)}
                            />
                        </Grid>
                        <Grid item sm={4}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox value={checkFechav} onChange={() => setCheckFechav(!checkFechav)} />} label="Tiene Fecha de Vencimiento" />
                                <FormControlLabel control={<Checkbox value={checkNC} onChange={() => setCheckNC(!checkNC)} />} label="Tiene Nivel de Cumplimiento" />
                            </FormGroup>
                        </Grid>
                        <Grid item sm={4}>
                            <TextField
                                label='Carpeta'
                                id='carpeta'
                                name='carpeta'
                                select
                                fullWidth
                                onChange={(e) => setNombreDocCarpeta(e.target.value)}
                            >
                                {carpetas.map(c => (
                                    <MenuItem key={c.nombre_carpeta} value={c.nombre_carpeta}>{c.nombre_carpeta}</MenuItem>
                                ))}
                                <MenuItem key="OTROS" value="OTROS">OTROS</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item sm={4}>
                            <Stack spacing={2} direction="row">
                                <Box sx={{
                                    margin: 2,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignContent: "center",
                                    display: "flex"
                                }}>
                                    <Stack spacing={2} direction="column">
                                        <Typography sx={{ textAlign: "center" }}>
                                            Carga Individual
                                        </Typography>
                                        <IconButton component="label">
                                            <CloudUploadIcon fontSize="large" />
                                            <input hidden type="file" multiple onChange={(e) => handleArchivo(e)} />
                                        </IconButton>
                                        {archivo !== '' ? (
                                            <p>{archivo.name}</p>
                                        ) : ("")}
                                    </Stack>
                                </Box>
                                <Stack direction="column" spacing={2}>
                                    <Typography sx={{ textAlign: "center" }}>
                                        Carga Masiva
                                    </Typography>
                                    <section className="container">
                                        <div {...getRootProps({ className: 'dropzone' })}>
                                            <input {...getInputProps()} />
                                            <p>Arrastre sus archivos aqui, o haga clic para seleccionarlos</p>
                                        </div>
                                        <aside>
                                            <h4>Archivos a Cargar</h4>
                                            <ul>{files}</ul>
                                        </aside>
                                    </section>
                                    <Button endIcon={<SaveIcon />} onClick={guardarMasivo}>Guardar Masivo</Button>
                                    {percent > 0 ? (
                                        <Grid item xs sm={3}>
                                            <CircularProgressWithLabel value={percent} />
                                        </Grid>
                                    ) : ""}
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item sm={4}>
                            <TextField
                                label='Obvservación'
                                name="obvservacion"
                                value={observacion}
                                multiline
                                rows={4}
                                fullWidth
                                onChange={(e) => setObservacion(e.target.value)}
                            />
                        </Grid>
                        <Grid item sm={4}>
                            {!edit ? (
                                <Button endIcon={<SaveIcon />} onClick={guardarPolitica}>Guardar Individual</Button>
                            ) : (
                                <Button endIcon={<UpdateIcon />} onClick={editarDocumento}>Actualizar</Button>
                            )}
                            {percent > 0 ? (
                                <Grid item xs sm={3}>
                                    <CircularProgressWithLabel value={percent} />
                                </Grid>
                            ) : ""}

                        </Grid>
                    </Grid>
                    {/* <Stack direction="column" spacing={2}>
                        <TextField
                            label="Nombre Documento"
                            name="nombre"
                            fullWidth
                            onChange={(e) => setNombre(e.target.value)}
                        />
                        <IconButton component="label">
                            <CloudUploadIcon fontSize="large" />
                            <input hidden type="file" multiple onChange={(e) => handleArchivo(e)} />
                        </IconButton>
                        {archivo !== '' ? (
                            <p>{archivo.name}</p>
                        ) : ("")}
                        <Button endIcon={<SaveIcon />} onClick={guardarPolitica}>Guardar</Button>
                        {percent > 0 ? (
                            <Grid item xs sm={3}>
                                <CircularProgressWithLabel value={percent} />
                            </Grid>
                        ) : ""}
                    </Stack> */}
                </Box>
                <Box sx={{
                    margin: 3,
                    justifyContent: "end",
                    alignContent: "end",
                    alignItems: "end",
                    display: "flex",
                }}>
                    <Button variant="contained" color="secondary" onClick={descargarArchivos}>Descargar Archivos</Button>
                </Box>
                {percentage > 0 ? (
                    <Box sx={{ width: '100%', margin: 3 }}>
                        <Typography variant='subtitle2'>
                            Descargando Archivos ...
                        </Typography>
                        <LinearProgressWithLabel value={percentage} />
                    </Box>
                ) : ""}
                <Box sx={{
                    margin: 2,
                }}>
                    <Button variant='contained' onClick={() => setCrearCarpeta(true)}>Crear Carpeta</Button>
                    {crearCarpeta ? (
                        <Stack direction="row" spacing={3} sx={{ margin: 2 }}>
                            <TextField
                                label="Nombre de Carpeta"
                                id="nombre_carpeta"
                                name='nombre_carpeta'
                                onChange={(e) => setNombreCarpeta(e.target.value)}
                            />
                            <Button variant='contained' color='success' onClick={crearC}>Crear</Button>
                        </Stack>
                    ) : ""}
                </Box>
                <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', margin: 3 }}>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => selCarpeta("TODAS")}>
                                <ListItemIcon>
                                    <FolderIcon />
                                </ListItemIcon>
                                <ListItemText primary="TODAS" />
                            </ListItemButton>
                        </ListItem>
                        {carpetas ? carpetas.map(c => (
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => selCarpeta(c.nombre_carpeta)}>
                                    <ListItemIcon>
                                        <FolderIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={c.nombre_carpeta} />
                                </ListItemButton>
                            </ListItem>
                        )) : (
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <FolderIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="TODAS" />
                                </ListItemButton>
                            </ListItem>
                        )}
                    </List>
                </Box>
                <Box sx={{
                    margin: 3,
                    justifyContent: "center",
                    alignItems: "center",
                    //display: "flex"
                }}>
                    <MaterialReactTable
                        data={politicas}
                        columns={columns}
                        enableColumnActions={false}
                        enableColumnFilters={false}
                        enableSorting={true}
                    />
                </Box>
                {/** MODAL BRECHAS */}
                <Modal
                    open={modalBrecha}
                    onClose={closeModalBrecha}
                    center
                >
                    <AppBar sx={{ backgroundColor: 'green' }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" align="center" style={{ marginBottom: 10 }}>
                            Ingresar Brecha
                        </Typography>
                    </AppBar>
                    <Box sx={{
                        margin: 3
                    }}>
                        <Grid container spacing={2} mt={3}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    rows={4}
                                    multiline
                                    fullWidth
                                    label="Brecha ..."
                                    onChange={(e) => setTextBrecha(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Responsable..."
                                    onChange={(e) => setResponsable(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Correo Responsable"
                                    onChange={(e) => setCorreoResponsable(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Estado"
                                    onChange={(e) => setEstado(e.target.value)}
                                    select
                                >
                                    <MenuItem key="NC" value="NC">NO CONFORMIDAD</MenuItem>
                                    <MenuItem key="OB" value="OB">OBSERVACION</MenuItem>
                                    <MenuItem key="OM" value="OM">OPORTUNIDAD DE MEJORA</MenuItem>
                                    <MenuItem key="OBPOS" value="OBPOS">OBSERVACION POSITIVA</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                    <DatePicker
                                        label="Fecha Limite"
                                        format='DD-MM-YYYY'
                                        onChange={(newValue) => setFechaLimite(newValue.toDate())}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ marginTop: 3, justifyContent: "right", alignItems: "end", display: "flex" }}>
                        <Stack direction="row" spacing={2}>
                            <Button variant='outlined' color='secondary' onClick={agregarBrecha}>Agregar Brecha</Button>
                            <Button variant='outlined' color='error' onClick={closeModalBrecha}>Cerrar</Button>
                        </Stack>
                    </Box>
                </Modal>
                {/** MODAL DE AUDITORIAS */}
                <Modal open={modalAuditoria} onClose={closeModalAuditoria} center>
                    <AppBar sx={{ backgroundColor: 'black' }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" align="center" style={{ marginBottom: 10 }}>
                            Ingresar Auditoria
                        </Typography>
                    </AppBar>
                    <Box sx={{
                        margin: 3
                    }}>
                        <Grid container spacing={2} mt={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Calificación de Hallazgo"
                                    onChange={(e) => handleAuditoria(e.target.value, 'calificacion_halazgo')}
                                    select
                                >
                                    <MenuItem key="NC" value="NC">NO CONFORMIDAD</MenuItem>
                                    <MenuItem key="OB" value="OB">OBSERVACION</MenuItem>
                                    <MenuItem key="OM" value="OM">OPORTUNIDAD DE MEJORA</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    rows={4}
                                    multiline
                                    fullWidth
                                    label="Descripción del hallazgo ..."
                                    onChange={(e) => handleAuditoria(e.target.value, 'descripcion_hallazgo')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    rows={4}
                                    multiline
                                    fullWidth
                                    label="Acción Correctiva ..."
                                    onChange={(e) => handleAuditoria(e.target.value, 'accion_correctiva')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Responsable"
                                    onChange={(e) => handleAuditoria(e.target.value, 'responsable')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                    <DatePicker
                                        label="Fecha Implementación"
                                        format='DD-MM-YYYY'
                                        onChange={(newValue) => handleAuditoria(newValue.toDate(), 'fecha_implementacion')}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ marginTop: 3, justifyContent: "right", alignItems: "end", display: "flex" }}>
                        <Stack direction="row" spacing={2}>
                            <Button variant='outlined' color='secondary' onClick={ingresarAuditoria}>Agregar Auditoria</Button>
                            <Button variant='outlined' color='error' onClick={() => setModalAuditoria(false)}>Cerrar</Button>
                        </Stack>
                    </Box>
                </Modal>
            </Container>
        </div>
    )
}

export default function DocsRessoSanck() {
    return (
        <SnackbarProvider maxSnack={3}>
            <DocsResso />
        </SnackbarProvider>
    )
}