import http from "../http-common-generatepdf";

class GenerarPDFService {

    geneararPDF(data) {
        return http.post("/generarpdf", data, {
            responseType: 'arraybuffer',
        });
    }
}

export default new GenerarPDFService();