import React, { useCallback, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams } from "react-router-dom";

/** MUI */
import {
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    TextField,
    Tooltip
} from '@mui/material';


/** MATERIAL REACT TABLE */
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';

/** FIrebase */
import { collection, deleteDoc, doc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import FireBaseApp from "../firebase/firebase";

/** MODELS */
import { Documento } from '../models/documento.model';

/** ICONOS */
import { Delete, Edit } from '@mui/icons-material';

import ResponsiveAppBar from "../components/navbar.component";

const documentoConverter = {
    toFirestore: (documento) => {
        return {
            numero: documento.numero,
            nombre: documento.nombre,
            abreviatura: documento.abreviatura,
            id_empresa: documento.id_empresa
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new Documento(data.numero, data.nombre, data.abreviatura, data.id_empresa);
    }
};

const IngresoDocumentos = () => {
    const [documentos, setDocumentos] = useState([]);
    const [document, setDocument] = useState('');
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const { id_contrato } = useParams();
    const [user, loading_user, error] = useAuthState(FireBaseApp.auth);
    const [usuario, setUsuario] = useState('');

    useEffect(() => {
        //await retreiveUser(user.uid);
        //if(usuario) alert("Usuario disponible"); else alert("Usuario no disponible");
        //setUsuario(JSON.parse(localStorage.getItem("usuario")));
        const usuar = JSON.parse(localStorage.getItem("usuario"));
        setUsuario(usuar);
        console.log("User", usuar);
        retreiveDocumentos(usuar.id_empresa);
    }, []);

    async function retreiveDocumentos(id_empresa) {
        var temp = [];
        const queryDocumentos = await query(collection(FireBaseApp.db, "maestro_documentos").withConverter(documentoConverter), where("id_empresa", "==", id_empresa));
        const snapDocumentos = await collection(FireBaseApp.db, "maestro_documentos").withConverter(documentoConverter);
        const dataDocumentos = await getDocs(queryDocumentos);
        dataDocumentos.forEach(docDoc => {
            console.log(docDoc.id, " => ", docDoc.data());
            const dataDoc = { id: docDoc.id, ...docDoc.data() };
            temp.push(dataDoc);
        });
        temp.sort(function (a, b) {
            if (parseInt(a.numero) > parseInt(b.numero)) {
                return 1;
            } else {
                return -1;
            }
        });
        setDocumentos([...temp]);
    }

    const addDocumento = async () => {
        const docRef = doc(collection(FireBaseApp.db, "maestro_documentos")).withConverter(documentoConverter);
        setDoc(docRef, new Documento(document.numero, document.nombre));
    };

    const handleDocument = (valor, nombre) => {
        setDocument({ ...document, [nombre]: valor });
    }

    const columns = [
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'ID',
            enableEditing: false,
        },
        {
            accessorKey: 'numero', //access nested data with dot notation
            header: 'Número',
        },
        {
            accessorKey: 'nombre', //access nested data with dot notation
            header: 'Nombre',
        },
        {
            accessorKey: 'abreviatura', //access nested data with dot notation
            header: 'Abreviatura',
        }
    ];

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        documentos[row.index] = values;
        console.log(values);
        //send/receive api updates here, then refetch or update local table data for re-render
        setDocumentos([...documentos]);
        //Editamos en Firestore
        const docRef = doc(collection(FireBaseApp.db, "maestro_documentos"), values.id).withConverter(documentoConverter);
        updateDoc(docRef, {
            nombre: values.nombre,
            numero: values.numero,
            abreviatura: values.abreviatura,
            id_empresa: usuario.id_empresa,
        });
        exitEditingMode();
    };

    const handleDeleteRow = useCallback(
        (row) => {
            if (
                !window.confirm(`Are you sure you want to delete ${row.getValue('nombre')}`)
            ) {
                return;
            }
            //send api delete request here, then refetch or update local table data for re-render
            const docRef = doc(collection(FireBaseApp.db, "maestro_documentos"), row.original.id).withConverter(documentoConverter);
            deleteDoc(docRef);
            documentos.splice(row.index, 1);
            setDocumentos([...documentos]);
        },
        [documentos],
    );

    const handleCreateNewRow = (values) => {
        console.log(values.nombre);
        documentos.push(values);
        setDocumentos([...documentos]);
        const docRef = doc(collection(FireBaseApp.db, "maestro_documentos")).withConverter(documentoConverter);
        setDoc(docRef, new Documento(values.numero, values.nombre, values.abreviatura, usuario.id_empresa));
    };

    async function retreiveUser(uid) {
        const queryUser = await query(collection(FireBaseApp.db, "users"), where("uid", "==", uid));
        const snapUser = await getDocs(queryUser);
        if (!snapUser.empty) {
            setUsuario({ id: snapUser.docs[0].id, ...snapUser.docs[0].data() });
        } else {
            alert("Error al cargar usuario !!");
            return;
        }
    };

    return (
        <div>
            <ResponsiveAppBar id_contrato={id_contrato} id_empresa={usuario.id_empresa} />
            <Container>
                {/* <Box sx={{
                    margin: 3,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex"
                }}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <TextField
                                label="Número"
                                name="numero"
                                fullWidth
                                onChange={(e) => handleDocument(e.target.value, "numero")}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                label="Nombre Documento"
                                name="nombre_documento"
                                fullWidth
                                onChange={(e) => handleDocument(e.target.value, "nombre")}
                            />
                        </Grid>
                        <Grid item>
                            <Button variant="contained" onClick={addDocumento}>AGREGAR</Button>
                        </Grid>
                    </Grid>
                </Box> */}
                <Box sx={{
                    margin: 3
                }}>
                    <MaterialReactTable
                        data={documentos}
                        columns={columns}
                        editingMode="modal"
                        enableEditing
                        localization={MRT_Localization_ES}
                        initialState={{ columnVisibility: { id: false } }}
                        onEditingRowSave={handleSaveRowEdits}
                        renderRowActions={({ row, table }) => (
                            <Box sx={{ display: 'flex', gap: '1rem' }}>
                                <Tooltip arrow placement="left" title="Edit">
                                    <IconButton onClick={() => table.setEditingRow(row)}>
                                        <Edit />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip arrow placement="right" title="Delete">
                                    <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}
                        renderTopToolbarCustomActions={() => (
                            <Button
                                color="secondary"
                                onClick={() => setCreateModalOpen(true)}
                                variant="contained"
                            >
                                Crear Nuevo Documento
                            </Button>
                        )}
                    />
                    <CreateNewAccountModal
                        columns={columns}
                        open={createModalOpen}
                        onClose={() => setCreateModalOpen(false)}
                        onSubmit={handleCreateNewRow}
                    />
                </Box>
            </Container>
        </div>
    )
};

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({ open, columns, onClose, onSubmit }) => {
    const [values, setValues] = useState(() =>
        columns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {}),
    );

    const handleSubmit = () => {
        //put your validation logic here
        onSubmit(values);
        onClose();
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Crear Nuevo Documento</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        {columns.map((column) => (
                            <TextField
                                key={column.accessorKey}
                                label={column.header}
                                name={column.accessorKey}
                                onChange={(e) =>
                                    setValues({ ...values, [e.target.name]: e.target.value })
                                }
                            />
                        ))}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={onClose}>Cancel</Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                    Crear Nuevo Documento
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default IngresoDocumentos;