import moment from "moment";
import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { useParams } from "react-router-dom";
import { DotLoaderOverlay } from 'react-spinner-overlay';
import saveAs from 'save-as';

/** MUI */
import {
    AppBar,
    Box,
    Button,
    IconButton,
    Tooltip,
    Typography
} from '@mui/material';

import MaterialReactTable from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';

/** FireBase */
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import FireBaseApp from "../firebase/firebase";

/** Componentes */
import ResponsiveAppBar from "../components/navbar.component";

/** ICONOS */
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VisibilityIcon from '@mui/icons-material/Visibility';

/** Servicios */
import generarpdfService from '../services/generarpdf.service';

const Auditorias = () => {
    const { id_contrato } = useParams();
    const [auditoriass, setAuditoriass] = useState([]);
    const [loading, setLoading] = useState(false);
    const [contrato, setContrato] = useState('');
    const [modalLista, setModalLista] = useState(false);
    const [listaA, setListaA] = useState([]);

    useEffect(() => {
        retreiveAuditorias(id_contrato);
        retreiveContrato(id_contrato);
    }, []);

    const closeModalLista = () => setModalLista(false);

    async function retreiveContrato(id_cont) {
        const contData = await getDoc(doc(FireBaseApp.db, "contrato", id_cont));
        setContrato(contData.data());
    };

    async function retreiveAuditorias(id_contrato) {
        setLoading(true);
        const queryAudi = await query(collection(FireBaseApp.db, "auditoria_documental"), where("id_contrato", "==", id_contrato));
        const dataAudi = await getDocs(queryAudi);
        var temp_arr = [];
        if (!dataAudi.empty) {
            dataAudi.forEach(async docA => {
                const contratoRef = await doc(FireBaseApp.db, "contrato", id_contrato);
                const dataContarto = await getDoc(contratoRef);
                const data_auditoria = {
                    id: docA.id,
                    fecha: moment(docA.data().fecha.toDate()).utc().format("DD-MM-YYYY"),
                    contrato: dataContarto.data().abreviado,
                    estado: docA.data().estado,
                    arr_auditorias: docA.data().arr_auditorias,
                };
                temp_arr.push(data_auditoria);
                setAuditoriass([...temp_arr]);
                setLoading(false);
            });
        } else {
            setLoading(false);
            console.error("Data Vacia");
        }
    };

    async function getItems() {
        var cate_temp = [];
        var valor = 0;
        const catRef = await query(collection(FireBaseApp.db, "categoria"), where("id_contrato", "==", id_contrato));
        const snapCats = await getDocs(catRef);
        const cats = snapCats.docs;
        for (var c in cats) {
            const querySubcat = await query(collection(FireBaseApp.db, "subcategoria"), where("id_categoria", "==", cats[c].id));
            const snapSubCat = await getDocs(querySubcat);
            const subcats = snapSubCat.docs;
            for (var sc in subcats) {
                const queryItems = await query(collection(FireBaseApp.db, "items"), where("id_subcategoria", "==", subcats[sc].id));
                const snapItems = await getDocs(queryItems);
                const items = snapItems.docs;
                for (var i in items) {
                    //console.log(items[i].data());
                    if (items[i].data().ponderacion) {
                        cate_temp.push(items[i].data());
                    }
                }
            }
        }
        return cate_temp;
    }

    async function resultadoAuditorio() {
        var cate_temp = [];
        const catRef = await query(collection(FireBaseApp.db, "categoria"), where("id_contrato", "==", id_contrato));
        const snapCats = await getDocs(catRef);
        if (!snapCats.empty) {
            snapCats.forEach(async (docCat) => {
                //Sumamos los resultados parciales de las subcategorias
                var ponderacion_temp = 0;
                var resultado_parcial = 0;
                var porc_item = 0;
                const querySubcat = await query(collection(FireBaseApp.db, "subcategoria"), where("id_categoria", "==", docCat.id));
                const snapSubCat = await getDocs(querySubcat);
                for (var sc in snapSubCat.docs) {
                    //ponderacion_temp = ponderacion_temp + snapSubCat.docs[sc].data().ponderacion_parcial;
                    const queryItems = await query(collection(FireBaseApp.db, "items"), where("id_subcategoria", "==", snapSubCat.docs[sc].id));
                    const snapItems = await getDocs(queryItems);
                    snapItems.forEach((docI) => {
                        if (docI.data().resultado) {
                            resultado_parcial = resultado_parcial + docI.data().resultado;
                        }
                    });
                    ponderacion_temp = ponderacion_temp + snapSubCat.docs[sc].data().ponderacion_parcial;
                    //resultado_parcial = await calculoNivelCumplimiento(snapSubCat.docs[sc].id);//resultado_parcial+docSubCat.data().resultado_parcial;
                    porc_item = Math.round((resultado_parcial * 100) / ponderacion_temp, -2);
                }
                /* snapSubCat.forEach(async (docSubCat) => {
                    ponderacion_temp = ponderacion_temp + docSubCat.data().ponderacion_parcial;
                    const queryItems = await query(collection(FireBaseApp.db,"items"),where("id_subcategoria","==",docSubCat.id));
                    const snapItems = await getDocs(queryItems);
                    snapItems.forEach((docI)=>{
                        if(docI.data().resultado) {
                            resultado_parcial = resultado_parcial + docI.data().resultado;
                        }
                    });
                    //resultado_parcial = await calculoNivelCumplimiento(docSubCat.id);//resultado_parcial+docSubCat.data().resultado_parcial;
                    porc_item = Math.round((resultado_parcial*100)/ponderacion_temp, -2);
                }); */
                var cat_data = { id: docCat.id, ...docCat.data(), ponderacion_categoria: ponderacion_temp, nivel_cumplimiento: resultado_parcial, porc_item: porc_item };
                console.log("Cat Data", cat_data);
                cate_temp.push(cat_data);
                // order an array of objects with name
                cate_temp.sort(function (a, b) {
                    if (a.nombre < b.nombre) {
                        return -1;
                    }
                    if (a.nombre > b.nombre) {
                        return 1;
                    }
                    return 0;
                });
            });
        }
        return cate_temp;
    }

    const generarInforme = async (id_auditoria) => {
        setLoading(true);
        let items_v2 = await getItems();
        //var items = await resultadoAuditorio();
        var valor = 0;
        var c_nc = 0;
        var c_ob = 0;
        var c_om = 0;
        const audit = auditoriass.find(v => v.id == id_auditoria);
        for(var i in items_v2) {
            if(items_v2[i].resultado) {
                
                var num_item = audit.arr_auditorias.find(v=>v.item === items_v2[i].numero);
                if(num_item) {
                    valor = valor + (items_v2[i].resultado/2);
                } else {
                    valor = valor + items_v2[i].resultado;
                }
            }
        }
        for(var i in audit.arr_auditorias) {
            if(audit.arr_auditorias[i].calificacion_halazgo === "OB") {
                c_ob = c_ob + 1;
            }
            if(audit.arr_auditorias[i].calificacion_halazgo === "NC") {
                c_nc = c_nc + 1;
            }
            if(audit.arr_auditorias[i].calificacion_halazgo === "OM") {
                c_om = c_om + 1;
            }
        }
        console.log("Valor: ", valor);
        console.log("NC", c_nc);
        console.log("OB", c_ob);
        console.log("OM", c_om);
        const dataPDF = {
            nombre_contrato: contrato.nombre,
            numero_contrato: contrato.numero,
            resultado_auditoria: valor.toString() + "%",
            c_ob: c_ob,
            c_om: c_om,
            c_nc: c_nc,
            table_data: audit.arr_auditorias,
        }
        generarpdfService.geneararPDF(dataPDF).then(resp => {
            console.log(resp);
            const blob = new Blob([resp.data], { type: 'application/pdf' });
            saveAs(blob, "informa_auditoria.pdf");
            setLoading(false);
        }).catch(err => {
            console.error(err);
            setLoading(false);
        });
    }


    const columns = [
        {
            accessorKey: 'contrato', //access nested data with dot notation
            header: 'Contrato',
        },
        {
            accessorKey: 'fecha', //access nested data with dot notation
            header: 'Fecha',
        },
        {
            accessorKey: 'estado', //access nested data with dot notation
            header: 'Estado',
            Cell: ({ cell }) => {
                if (cell.getValue() == true) {
                    return "Activo"
                } else {
                    return "Cerrado"
                }
            }
        },
    ];

    const colums_lista = [
        {
            accessorKey: 'item', //access nested data with dot notation
            header: 'N° Item',
        },
        {
            accessorKey: 'calificacion_halazgo', //access nested data with dot notation
            header: 'Calificación Hallazgo',
        },
        {
            accessorKey: 'descripcion_hallazgo', //access nested data with dot notation
            header: 'Descripción Hallazgo',
        },
        {
            accessorKey: 'accion_correctiva', //access nested data with dot notation
            header: 'Acción Correctiva',
        },
        {
            accessorKey: 'responsable', //access nested data with dot notation
            header: 'Responsable',
        },
        {
            accessorKey: 'fecha_implementacion', //access nested data with dot notation
            header: 'Fecha',
            Cell: ({ cell }) => {
                return (
                    <span>{moment(cell.getValue().toDate()).utc().format("DD-MM-YYYY")}</span>
                )
            }
        },
    ];

    const verItemsAuditoria = (id_auditoria) => {
        const audit = auditoriass.find(v => v.id == id_auditoria);
        setListaA(audit.arr_auditorias);
        setModalLista(true);
    }

    return (
        <div>
            {loading ? (
                <DotLoaderOverlay size={28} message="Cargando..." color="#000000" />
            ) : ""}
            <ResponsiveAppBar id_contrato={id_contrato} id_empresa={contrato.id_empresa} />
            <Box sx={{
                marginTop: 3,
            }}>
                <MaterialReactTable
                    columns={columns}
                    data={auditoriass}
                    localization={MRT_Localization_ES}
                    enableRowActions
                    renderRowActions={({ row, table }) => (
                        <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                            <Tooltip title="Descargar Informe">
                                <IconButton sx={{ color: 'black' }} onClick={() => generarInforme(row.original.id)}>
                                    <PictureAsPdfIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Cerrar Auditoria">
                                <Button sx={{ color: 'black' }}>Cerrar</Button>
                            </Tooltip>
                            <Tooltip title="Ver Items Ingresados">
                                <IconButton sx={{ color: 'black' }} onClick={() => verItemsAuditoria(row.original.id)}>
                                    <VisibilityIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                />
            </Box>
            {/** MODAL LISTA PUNTOS DE AUDITORIAS */}
            <Modal open={modalLista} onClose={closeModalLista} center>
                <AppBar sx={{ backgroundColor: 'black' }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" align="center" style={{ marginBottom: 10 }}>
                        Lista Items de Auditorias
                    </Typography>
                </AppBar>
                <Box sx={{
                    margin: 3
                }}>
                    <MaterialReactTable
                        columns={colums_lista}
                        data={listaA}
                        localization={MRT_Localization_ES}
                    />
                </Box>
            </Modal>
        </div>
    )
}
export default Auditorias;