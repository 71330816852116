import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DotLoaderOverlay } from 'react-spinner-overlay';

/** MUI */
import { Box, Button, Container, IconButton, Tooltip } from "@mui/material";

import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from 'material-react-table/locales/es';

/** ICONOS */
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';

/** FireBase */
import { collection, getDocs, query, where } from "firebase/firestore";
import FireBaseApp from "../firebase/firebase";

/** Componentes */
import Footer from "../components/footer.component";
import ResponsiveAppBar from "../components/navbar.component";

/**Servicios */
import personaService from "../services/persona.service";

const Personal = () => {
    const { id_contrato } = useParams();
    const [personas, setPersonas] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [contratos, setContratos] = useState([]);
    //const [usuario, setUsuario] = React.useState('');
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const [rowCount, setRowCount] = useState(0);
    const [user, setUser] = useState('');

    useEffect(() => {
        const usuario = JSON.parse(localStorage.getItem("usuario"));
        retreiveContratos(usuario.id_empresa);
        setUser(usuario);
        console.log("Usuario", usuario);
        if (usuario.perfil === "administrador" || usuario.perfil == "gerencia") {
            //retreiveAllPersonal();
            retreiveAllPersonalv2(usuario.id_empresa);
        } else {
            retreivePersonal(id_contrato);
        }
    }, []);

    function retreiveAllPersonalv2(id_empresa) {
        setLoading(true);
        personaService.getAllPersonasByEmpresa({id_empresa: id_empresa})
        .then(resp=>{
            console.log(resp.data);
            setPersonas(resp.data);
            setLoading(false);
        })
        .catch(error=>{
            console.error(error);
            setLoading(false);
        })
    };

    async function retreivePersonal(id_contrato) {
        setLoading(true);
        var per_temp = [];
        var cont_temp = [];
        const snapContratos = await getDocs(collection(FireBaseApp.db, "contrato"));
        snapContratos.forEach((conDoc) => {
            cont_temp.push({ id: conDoc.id, ...conDoc.data() });
        });
        const queryPersonas = await query(collection(FireBaseApp.db, "personas"), where("id_contrato", "==", id_contrato));
        const snapPersonas = await getDocs(queryPersonas);
        snapPersonas.forEach(async (docP) => {
            //Get contrato
            const docCont = cont_temp.filter(v => v.id === docP.data().id_contrato);
            var data_per = { id: docP.id, ...docP.data(), contrato: docCont[0] };
            per_temp.push(data_per);
        });
        setPersonas([...per_temp]);
        setLoading(false);
    };

    const handlePaginationChange = (newPagination) => {
        setPagination(newPagination);
    };

    async function retreiveContratos(id_empresa) {
        var cont_temp = [];
        const snapContratos = await getDocs(query(collection(FireBaseApp.db, "contrato"),where("id_empresa","==",id_empresa)));
        snapContratos.forEach((conDoc) => {
            //cont_temp.push({id: conDoc.id, ...conDoc.data()});
            cont_temp.push(conDoc.data().abreviado);
        });
        setContratos([...cont_temp]);
    }

    async function retreiveAllPersonal() {
        setLoading(true);
        var per_temp = [];
        var cont_temp = [];
        const snapContratos = await getDocs(collection(FireBaseApp.db, "contrato"));
        snapContratos.forEach((conDoc) => {
            cont_temp.push({ id: conDoc.id, ...conDoc.data() });
        });
        //const queryPersonas = await query(collection(FireBaseApp.db, "personas"));
        //const queryPer = query(collection(FireBaseApp.db, "personas"), orderBy('id_contrato'), startAfter(pag.pageIndex * pag.pageSize), limit(pag.pageSize))
        const snapPersonas = getDocs(collection(FireBaseApp.db, "personas"));//await getDocs(queryPer);//getDocs(collection(FireBaseApp.db, "personas"));
        /* snapPersonas.forEach(async (docP) => {
            //Get contrato
            //console.log("ID C", docP.data().id_contrato);
            const docCont = await getDoc(doc(FireBaseApp.db,"contrato",docP.data().id_contrato));
            //console.log("Cont C", docCont.data());
            var data_per = { id: docP.id, ...docP.data(), contrato:  docCont.data().abreviado };
            console.log("Data Per", data_per);
            per_temp.push(data_per);
        }); */
        for (var sp in snapPersonas.docs) {
            const docCont = cont_temp.filter(v => v.id === snapPersonas.docs[sp].data().id_contrato);//await getDoc(doc(FireBaseApp.db,"contrato",snapPersonas.docs[sp].data().id_contrato));
            //console.log(docCont);
            var data_per = { id: snapPersonas.docs[sp].id, ...snapPersonas.docs[sp].data(), contrato: docCont[0] };//v_contrato:  docCont.data().abreviado
            per_temp.push(data_per);
        }
        setPersonas([...per_temp]);
        setLoading(false);
    };

    /* async function retreiveUser(uid) {
        const queryUsers = await query(collection(FireBaseApp.db, "users"), where("uid", "==", uid));
        const snapUsers = await getDocs(queryUsers);
        setUsuario({ id: snapUsers.docs[0].id, ...snapUsers.docs[0].data() });
    } */

    const columns = [
        {
            accessorKey: 'rut', //access nested data with dot notation
            header: 'Rut',
            size: 20
        },
        {
            accessorKey: 'nombre', //access nested data with dot notation
            header: 'Nombres',
            size: 20
        },
        {
            accessorKey: 'apellido_paterno', //access nested data with dot notation
            header: 'Apeliido Paterno',
            size: 20
        },
        {
            accessorKey: 'cargo', //access nested data with dot notation
            header: 'Cargo',
            size: 20
        },
        {
            accessorKey: 'contrato.abreviado', //access nested data with dot notation
            header: 'Contrato',
            size: 20,
            filterVariant: 'select',
            filterSelectOptions: contratos,
        },
        /* {
            accessorKey: 'rut_', //access nested data with dot notation
            header: 'Docs',
            size: 20,
            Cell: ({ cell, row }) => {
                return (
                    <Button variant="contained" onClick={() => navigate("/documento/" + row.original.rut + "/" + id_contrato)}>Documentos</Button>
                )
            }
        } */
    ];

    return (
        <div>
            {loading ? (
                <DotLoaderOverlay size={28} message="Cargando..." color="#2ECC71" />
            ) : ""}
            <ResponsiveAppBar id_contrato={id_contrato} id_empresa={user.id_empresa} />
            <Container>
                <Box sx={{
                    margin: 3
                }}>
                    <Box sx={{
                        margin: 3,
                        justifyContent: "end",
                        alignItems: "end",
                        display: "flex"
                    }}>
                        <Button variant="contained" color="secondary" onClick={() => navigate("/agregarpersonal/" + id_contrato + "/0")}>Agregar Trabajador</Button>
                    </Box>
                    <MaterialReactTable
                        columns={columns}
                        data={personas}
                        localization={MRT_Localization_ES} //() => navigate("/documento/" + row.original.rut + "/" + id_contrato)
                        enableRowActions
                        renderRowActions={({ row, table }) => (
                            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                                <Tooltip title="Ver Documentos">
                                    <IconButton color='primary' onClick={() => window.open("/documento/" + row.original.rut + "/" + id_contrato,"_blank",'noreferrer')}>
                                        <PlagiarismIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Editar Trabajador">
                                    <IconButton color='primary' onClick={() => window.open("/agregarpersonal/" + id_contrato + "/" + row.original.id,"_blank",'noreferrer')}>
                                        <AssignmentIndIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}
                    />
                </Box>
            </Container>
            <Footer />
        </div>
    )
}

export default Personal;